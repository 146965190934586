import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FileIcon from '@mui/icons-material/FilePresent';
import { colors, Button } from '../lib/styles';
import ImportEca from '../components/import-eca';
import ImportHHEca from '../components/import-eca-hh';
import ImportEcaCarrier from '../components/import-eca-carrier';
import DownloadButton from '../components/download-csv';
import DeleteButton from '../components/delete-button';
import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';
import { Select, TextField } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const ECA = () => {
  const classes = useStyles();
  const history = useHistory();
  const [err] = useState(null);
  const [open, setOpen] = useState(false);
  const [openHH, setOpenHH] = useState(false);
  const [openCarrier, setOpenCarrier] = useState(false);
  const [carrier, setCarrier] = useState('all');
  const [status, setStatus] = useState('all');
  const [matchId, setMatchId] = useState(null);
  const [matchResults, setMatchResults] = useState(null);
  const [policyNumber, setPolicyNumber] = useState('');

  let url = '/eca?orderBy=statement_date&limit=5000';
  if (policyNumber && policyNumber.length > 2) {
    url = `/eca/search?policy_number=${policyNumber}`;
  }
  if (carrier !== 'all') {
    url += `&carrier=${encodeURIComponent(carrier)}`;
  }
  if (status !== 'all') {
    url += `&status=${status}`;
  }

  const { data: eca, loading: ecaLoading } = useSWR(url);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'affiliate',
      headerName: 'Affiliate',
      align: 'left',
      width: 150,
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      align: 'left',
      width: 150,
    },
    {
      field: 'agent_full_name',
      headerName: 'Agent',
      align: 'left',
      width: 150,
    },
    {
      field: 'client_name',
      headerName: 'Client',
      align: 'left',
      width: 150,
    },
    {
      field: 'policy_number',
      headerName: 'Policy #',
      align: 'left',
      width: 200,
    },
    {
      field: 'product_name',
      headerName: 'Product',
      align: 'left',
      width: 150,
    },
    {
      field: 'policy_type',
      headerName: 'Type',
      align: 'left',
      width: 150,
    },
    {
      field: 'statement_date',
      headerName: 'Statement Date',
      align: 'right',
      width: 120,
      renderCell: (params) => {
        return <span>{params.row.statement_date ? params.row.statement_date.substring(0, 10) : ''}</span>;
      },
    },
    {
      field: 'premium_amount',
      headerName: 'Premium',
      align: 'right',
      width: 150,
    },
    {
      field: 'commission',
      headerName: 'Commission',
      align: 'right',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        let { status } = params.row;
        if (params.row.status === 'PROCESSED') {
          status = 'MATCHED';
        }
        return <span>{status}</span>;
      },
    },
    {
      field: 'bsf_id',
      headerName: 'BSF',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (params.row.bsf_id) {
          return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${params.row.bsf_id}`}>BSF</Link>;
        }
        return '';
      },
    },
    {
      field: 'created_at',
      headerName: 'Uploaded On',
      align: 'right',
      width: 120,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
      },
    },
    {
      field: 'match',
      headerName: 'Match',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            onClick={async () => {
              const { id } = params.row;
              setMatchId(id);
              setMatchResults(null);
              try {
                setMatchResults(await apiFetch(`/eca/${id}/checkBSF`, { body: {}, method: 'PUT' }));
              } catch (e) {
                setMatchResults({ msg: e.message });
              }
            }}
          >
            Match BSF
          </Button>
        );
      },
    },
    {
      field: 'del',
      headerName: '.',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if ((params.row.status === 'OPEN') && !params.row.bsf_id) {
          return (
            <DeleteButton
              handleDelete={async () => {
                await apiFetch(`/eca/${params.row.id}`, { method: 'DELETE' });
                mutate(url);
              }}
            />
          );
        }
        return '';
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenHH = () => {
    setOpenHH(true);
  };

  const handleCloseHH = () => {
    setOpenHH(false);
  };

  const handleClickOpenCarrier = () => {
    setOpenCarrier(true);
  };

  const handleCloseCarrier = () => {
    setOpenCarrier(false);
  };

  const { data: carriers } = useSWR('/eca/carriers');

  return (
    <Layout headerTitle="Comp Uploads">
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '100%' }}>
          {carriers ? (
            <Box gridColumn="span 3">
              <Select
                label="Carrier"
                value={carrier}
                name="carrier"
                onChange={(evt) => {
                  setCarrier(evt.target.value);
                }}
              >
                <MenuItem value="all">All</MenuItem>
                {carriers.map((c, idx) => {
                  return (<MenuItem value={c.carrier} key={idx}>{c.carrier}</MenuItem>);
                })}
              </Select>
            </Box>
          ) : ''}
          <Box gridColumn="span 3">
            <Select
              select="all"
              label="Status"
              value={status}
              name="status"
              onChange={(evt) => {
                setStatus(evt.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
              <MenuItem value="PROCESSED">PROCESSED</MenuItem>
              <MenuItem value="CHARGEBACK">CHARGEBACK</MenuItem>
            </Select>
          </Box>
          <Box gridColumn="span 3">
            <TextField
              label="Policy #"
              value={policyNumber}
              name="policyNumber"
              onChange={(evt) => {
                setPolicyNumber(evt.target.value);
              }}
            />
          </Box>
          {eca && eca.length ? (
            <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
              <DownloadButton rows={eca} name="eca" style={{ color: colors.darkAccent }}/>
            </Box>
          ) : ''}
        </Box>
        <Paper>
          {!ecaLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={eca || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
        <Button gold sx={{ margin: '15px' }} onClick={handleClickOpen}>
          Upload ECA(s)
        </Button>
        <Button gold sx={{ margin: '15px' }} onClick={handleClickOpenHH}>
          Upload HH ECA(s)
        </Button>
        <Button gold sx={{ margin: '15px' }} onClick={handleClickOpenCarrier}>
          Carrier-Specific Upload
        </Button>
        <Button
          gold
          sx={{ margin: '15px' }}
          onClick={() => history.push('/eca-uploaded-files')}
        >
          <FileIcon /> Uploaded Files
        </Button>
      </div>
      <Dialog open={!!matchId}>
        <div style={{ margin: '10px' }}>
          <DialogTitle style={{ color: 'black' }}>Match BSF/ECA</DialogTitle>
          {matchResults ? (
            <div>
              <div>{matchResults.msg ? matchResults.msg : ''}</div>
              <div>
                <Typography variant="h6" style={{ color: 'black' }}>ECA</Typography>
                <div>Policy: {matchResults.eca?.policy_number}</div>
                <div>Carrier: {matchResults.eca?.carrier}</div>
              </div>
              <div>
                <Typography variant="h6" style={{ color: 'black' }}>BSF</Typography>
                <div>Policy: {matchResults.bsf?.policy_number}</div>
                <div>Carrier: {matchResults.bsf?.carrier_code}</div>
              </div>
            </div>
          ) : ''}
          <div>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setMatchId(null);
                setMatchResults(null);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Dialog>
      <ImportEca
        open={open}
        onClose={handleClose}
      />
      <ImportHHEca
        open={openHH}
        onClose={handleCloseHH}
      />
      <ImportEcaCarrier
        open={openCarrier}
        onClose={handleCloseCarrier}
      />
    </Layout>
  );
};

export default ECA;
