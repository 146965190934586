import React from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { colors } from '../lib/styles';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const MissedMoney = (props) => {
  const classes = useStyles();
  const { data } = props;

  const rows = (data || []).map((p, id) => {
    return {
      ...p,
      id,
      total_amount: Number(p.total_amount),
      total_max_amount: Number(p.total_max_amount),
      missed: Number(p.total_max_amount) - Number(p.total_amount),
    };
  });

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'total_amount',
      headerName: 'Earned',
      align: 'right',
      width: 140,
      renderCell: (params) => {
        return <span>{Number(params.row.total_amount).toFixed(2)}</span>;
      },
    },
    {
      field: 'total_max_amount',
      headerName: 'Max available',
      align: 'right',
      width: 140,
      renderCell: (params) => {
        return <span>{Number(params.row.total_max_amount).toFixed(2)}</span>;
      },
    },
    {
      field: 'missed',
      headerName: 'Missed',
      align: 'right',
      width: 140,
      renderCell: (params) => {
        return <span>{Number(params.row.missed).toFixed(2)}</span>;
      },
    },
    {
      field: 'missed_start',
      headerName: 'Year',
      align: 'right',
      width: 160,
      renderCell: (params) => {
        return <span>{moment(params.row.missed_start).add(12, 'hours').format('YYYY')}</span>;
      },
    },
  ];

  return (
    <div style={classes.mainBody}>
      <Paper className={classes.tintedBackground}>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={rows || []} />
        </div>
      </Paper>
    </div>
  );
};

export default MissedMoney;
