import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import throttle from 'lodash/throttle';
import { apiFetch } from '../lib/fetch';

function formatOption(o) {
  if (typeof o === 'string') {
    return o;
  }
  return `${o.firstname} ${o.lastname} ${o.num || ''} ${o.email} ${o.phone || ''} ${o.is_deleted ? ' (inactive)' : ''}`;
}

export default function UserSearch(props) {
  const [value, setValue] = React.useState(props.value);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(() => throttle(async (request, callback) => {
    let rows = await apiFetch('/users/search/all', { query: { term: request.input } });
    const ignore = props.ignore || [];
    rows = rows.filter((r) => {
      return !ignore.includes(r.id);
    });
    return callback(rows);
  }, 200), []);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      sx={{ flexGrow: 1, color: 'black' }}
      getOptionLabel={(option) => formatOption(option)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (props.onChange) {
          props.onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={props.label || 'Sponsor'} fullWidth />
      )}
      renderOption={(props, option) => {
        let icon = PersonIcon;
        let color = 'black';
        if (typeof option !== 'string') {
          if (option.is_deleted) {
            icon = PersonOffIcon;
            color = 'red';
          }
        }
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={icon}
                  sx={{ color, mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {formatOption(option)}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
