import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '../lib/styles';
import config from '../config';
import Layout from '../components/layout';
import { Select, Button } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  link: {
    color: colors.darkAccent,
  },
});

const SHPortionPayments = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const [status, setStatus] = useState('all');
  let url = '/sh_portion_payments?orderBy=created_at&limit=5000';

  if (status !== 'all') {
    url = `${url}&status=${status}`;
  }

  const { data: payments, loading: paymentsLoading } = useSWR(url);
  // const dlName = user ? `${user.firstname}_${user.lastname}_${user.num}_override-payments` : 'override-payments';
  let cleanPayments = [];
  if (payments && payments.length) {
    cleanPayments = payments.map((p) => {
      return { ...p, amount: Number(p.amount) };
    });
  }

  const columns = [
    {
      field: 'carrier_code',
      headerName: 'Carrier',
      align: 'right',
      width: 150,
    },
    {
      field: 'policy_number',
      headerName: 'Policy',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        const url = `/bsfs/${params.row.bsf_id}`;
        return <Link to={url} className={classes.link}>{params.row.policy_number}</Link>;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      width: 200,
      renderCell: (params) => {
        return <span>{Number(params.row.amount).toFixed(2)}</span>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'right',
      width: 110,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
      },
    },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   align: 'center',
    //   width: 100,
    //   renderCell: (params) => {
    //     const url = `/stronghold-commision-payments/${params.row.id}/edit`;
    //     return <Link to={url} className={classes.link}>Edit</Link>;
    //   },
    // },
  ];

  return (
    <Layout headerTitle="Stronghold Commission Payments" >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          <Box gridColumn="span 3">
            <Select
              label="Status"
              value={status}
              name="state"
              onChange={(evt) => {
                setStatus(evt.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="OPEN">Open</MenuItem>
              <MenuItem value="PAID">Paid</MenuItem>
            </Select>
          </Box>
        </Box>
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={cleanPayments} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
        <Button sx={{ marginTop: '10px' }} href={`${config.API_URL}/sh_portion/nacha`}>
          Download ACH
        </Button>
        <Button sx={{ marginTop: '10px', marginLeft: '10px' }} href="/upload/receipt">
          XML Records
        </Button>
      </div>
    </Layout>
  );
};

export default SHPortionPayments;
