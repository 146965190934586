import { json2csv } from 'json-2-csv';
import moment from 'moment';
import { getColumnLabels } from './columns';

async function download(rawRows, filename, type) {
  let rows = rawRows || [];
  if (type) {
    const allLabels = await getColumnLabels();
    if (allLabels[type]) {
      const labels = allLabels[type];
      rows = rows.map((row) => {
        const newRow = {};
        const keys = Object.keys(row);
        keys.forEach((key) => {
          const name = labels[key] || key;
          newRow[name] = row[key];
        });
        return newRow;
      });
    }
  }
  const csv = json2csv(rows);
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
  element.setAttribute('download', `${filename}-${moment().format('YYYY-MM-DD')}.csv`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  return true;
}

export default download;
