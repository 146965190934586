import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import useSWR from 'swr';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';
import { Button, MaskInput, TextField } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'carrier_code',
    headerName: 'Carrier',
    align: 'left',
    width: 150,
  },
  {
    field: 'policy_number',
    headerName: 'Policy #',
    align: 'left',
    width: 175,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 180,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
  {
    field: 'bsf_id',
    headerName: 'BSF',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      if (params.row.bsf_id || params.row.payment_bsf_id) {
        const bsfId = params.row.bsf_id || params.row.payment_bsf_id;
        return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${bsfId}`}>BSF</Link>;
      }
      return '';
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    align: 'left',
    width: 300,
  },
];

const Chargebacks = () => {
  const classes = useStyles();
  const params = useParams();
  const { userId } = params;
  const [err, setErr] = useState(null);
  const [user, setUser] = useState(null);
  const [chargebackAmount, setChargebackAmount] = useState('');
  const [chargebackNotes, setChargebackNotes] = useState('');
  useEffect(async () => {
    if (userId) {
      const res = await apiFetch(`/users/${userId}/stats`);
      setUser(res);
    }
  }, []);
  async function addChargeback() {
    if (chargebackAmount && user) {
      try {
        await apiFetch.post('/chargebacks', {
          eca_id: null,
          amount: -chargebackAmount,
          agent: user.id,
          override_payment_id: null,
          payment_id: null,
          type: 'MISC_CHARGEBACK',
          notes: chargebackNotes,
        });
        document.location.reload();
      } catch (e) {
        setErr(e.message);
      }
    }
  }

  const url = `/chargebacks?orderBy=created_at&limit=5000&agent=${userId}`;

  const { data: chargebacks, loading: chargebacksLoading } = useSWR(url);
  if (chargebacks && chargebacks.length) {
    chargebacks.forEach((c) => {
      // would be cool if pg didn't give us back strings for floats
      c.amount = Number(c.amount);
    });
  }

  return (
    <Layout headerTitle={`Chargeback Details ${user ? `for ${user.firstname} ${user.lastname} #${user.num}` : ''}`}>
      <div className={classes.container}>
        <Typography variant="h4">
          <div>Balance {user ? Number(user.chargebackBalance).toFixed(2) : ''}</div>
        </Typography>
        <div>{err?.toString()}</div>
        {chargebacks && chargebacks.length ? (<DownloadButton rows={chargebacks} name="chargebacks"/>) : ''}
        <Paper>
          {!chargebacksLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={chargebacks || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
      {user ? (
        <div style={{ margin: '20px' }}>
          <Typography variant="h4">
            Add Chargeback
          </Typography>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <MaskInput
                mask_type="currency"
                style={{ width: 200, margin: '10px' }}
                label="Amount"
                value={chargebackAmount}
                onChange={(evt) => {
                  setChargebackAmount(evt.value);
                }}
              />
              <TextField
                label="Notes"
                style={{ width: 220, margin: '10px' }}
                value={chargebackNotes}
                onChange={(evt) => {
                  setChargebackNotes(evt.target.value);
                }}
                multiline={true}
              />
            </div>
            <Button
              variant="contained"
              onClick={() => {
                addChargeback();
              }}
              disabled={!chargebackAmount}
            >
              Add Chargeback
            </Button>
          </div>
        </div>
      ) : ''}
    </Layout>
  );
};

export default Chargebacks;
