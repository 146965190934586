import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import makeStyles from '@mui/styles/makeStyles';
import { capitalize } from 'lodash';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import UserHeader from '../components/user-header';
import Layout from '../components/layout';
import { Select } from '../lib/common';

const DEFAULT_URL = '/stronghold_payments?orderBy=created_at&limit=5000';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 80,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'carrier_name',
    headerName: 'Carrier',
    align: 'left',
    width: 150,
  },
  {
    field: 'policy_number',
    headerName: 'Policy #',
    align: 'left',
    width: 160,
  },
  {
    field: 'client_name',
    headerName: 'Client',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 200,
    renderCell: (params) => {
      return <span>${Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 110,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
  {
    field: 'bsf_id',
    headerName: 'BSF',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      if (params.row.bsf_id) {
        return <Link to={`/bsfs/${params.row.bsf_id}`} style={{ color: colors.darkAccent }}>BSF</Link>;
      }
      return '';
    },
  },
];

const filteredColumns = (filter) => [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 90,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'count',
    headerName: 'Records',
    align: 'right',
    width: 120,
  },
  {
    field: 'total_amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      const totalAmount = Number(params.row.total_amount);
      if (!Number.isNaN(totalAmount)) {
        return <span>${totalAmount.toFixed(2)}</span>;
      }
      // Handle the case when total_amount is not a valid number
      return <span>N/A</span>;
    },
  },
  {
    field: 'created',
    headerName: capitalize(filter),
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created ? params.row.created.substring(0, 10) : ''}</span>;
    },
  },
];

const cleanData = (data, filtered = false) => {
  if (filtered) {
    return (data || []).map((p, id) => {
      return {
        ...p,
        id,
        total_amount: Number(p.total_amount),
      };
    });
  }

  return (data || []).map((p, idx) => {
    return {
      ...p,
      id: p.id || idx,
      amount: Number(p.amount),
    };
  });
};

const StrongholdPayments = () => {
  const classes = useStyles();
  const params = useParams();
  const { userId } = params;
  const [err] = useState(null);
  const [filter, setFilter] = useState('all');
  const [cols, setCols] = useState(columns);
  const [csvName, setCsvName] = useState('wealthshare_collection');
  const [url, setUrl] = useState(
    userId ? `/stronghold_payments?orderBy=created_at&limit=5000&agent=${userId}` : DEFAULT_URL,
  );
  const { data: user } = useSWR(`/users/${userId}`);
  const { data: payments, loading: paymentsLoading } = useSWR(url);

  const cleanPayments = useMemo(() => cleanData(payments || [], filter !== 'all'), [payments, filter]);

  useEffect(() => {
    switch (filter) {
      case 'all':
        setUrl(DEFAULT_URL);
        setCols(columns);
        setCsvName(user ? `${user.firstname}_${user.lastname}_${user.num}_ws_collection` : 'wealthshare_collection');
        break;
      case 'week':
      case 'month':
        setUrl(`/stronghold_payments/${filter}?orderBy=created&limit=5000`);
        setCols(filteredColumns(filter));
        setCsvName(
          user
            ? `${user.firstname}_${user.lastname}_${user.num}_ws_collection-${filter}ly`
            : `wealthshare_collection-${filter}ly`,
        );
        break;
      default:
        break;
    }
  }, [filter]);

  const renderErrorMessage = () => {
    if (err) {
      return err.toString();
    }
    return null;
  };

  return (
    <Layout headerTitle="Wealth Share Collection">
      <div className={classes.container}>
        <div>{renderErrorMessage()}</div>
        {user ? <UserHeader user={user} /> : ''}
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          <Box gridColumn="span 3">
            <Select
              label="Filter"
              value={filter}
              name="state"
              onChange={(evt) => {
                setFilter(evt.target.value);
              }}
            >
              <MenuItem value="all">View All</MenuItem>
              <MenuItem value="week">View by Week</MenuItem>
              <MenuItem value="month">View by Month</MenuItem>
            </Select>
          </Box>
          {cleanPayments && cleanPayments.length ? (
            <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
              <DownloadButton rows={cleanPayments} name={csvName} />
            </Box>
          ) : (
            ''
          )}
        </Box>
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={cols} rows={cleanPayments} />
            </div>
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </div>
    </Layout>
  );
};

export default StrongholdPayments;
