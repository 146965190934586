import React, { useState } from 'react';
import useSWR from 'swr';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '../lib/styles';
import Layout from '../components/layout';
import config from '../config';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  link: {
    color: colors.darkAccent,
  },
});

const EcaUploadedFiles = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const url = '/comp_upload_files/files?orderBy=created_at&limit=5000';

  const { data: payments, loading: paymentsLoading } = useSWR(url);
  // const dlName = user ? `${user.firstname}_${user.lastname}_${user.num}_override-payments` : 'override-payments';
  let cleanPayments = [];
  if (payments && payments.length) {
    cleanPayments = payments.map((p) => {
      return { ...p, amount: Number(p.amount) };
    });
  }

  const columns = [
    {
      field: 'name',
      headerName: 'File Name',
      align: 'right',
      width: 250,
    },
    {
      field: 'download',
      headerName: 'Download',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        if (params.row.name) {
          const url = `${config.API_URL}/comp_upload_files/${params.row.id}/download`;
          return (
            <a
              href={url}
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              download
            </a>
          );
        }
        return '';
      },
    },
    {
      field: 'created_at',
      headerName: 'Created',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
      },
    },
    // {
    //   field: 'edit',
    //   headerName: 'Edit',
    //   align: 'center',
    //   width: 100,
    //   renderCell: (params) => {
    //     const url = `/stronghold-commision-payments/${params.row.id}/edit`;
    //     return <Link to={url} className={classes.link}>Edit</Link>;
    //   },
    // },
  ];

  return (
    <Layout headerTitle="Uploaded Commission Files" >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={cleanPayments} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default EcaUploadedFiles;
