/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

import makeStyles from '@mui/styles/makeStyles';

import UserForm from '../components/user-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const User = () => {
  const [user, setUser] = useState({
    email: '',
    firstname: '',
    is_deleted: false,
    lastname: '',
    bio: '',
    address: '',
    city: '',
    state: '',
    phone: '',
    postalcode: '',
    parent_id: null,
    active: true,
    start_date: null,
    approved: false,
    tags: [],
  });
  const [parent, setParent] = useState();
  const [membership, setMembership] = useState();
  const location = useParams();
  const loc = useLocation();
  const [isNew, setIsNew] = useState(false);
  const { userId } = location;
  const [userEvents, setUserEvents] = useState({});

  function findUser(id) {
    return apiFetch(`/users/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(loc.pathname === '/users/new');
    let parent;
    if (userId) {
      const child = await findUser(userId);
      setUser(child);

      const { parent_id } = await child;

      const membership = await apiFetch('/memberships', { method: 'GET' })
        .then((res) => res.find((r) => r.user_id === userId));

      if (membership) {
        setMembership(membership);
      } else {
        setMembership({
          group_id: 'USER',
        });
      }

      const latestEvents = await apiFetch(`/users/${userId}/latest_events`);
      setUserEvents(latestEvents);

      if (parent_id) {
        parent = await findUser(parent_id);
        setParent(parent);
      }
    } else {
      setMembership({
        group_id: 'USER',
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'is_deleted') {
      value = !user.is_deleted;
    }

    setUser({
      ...user,
      [name]: value,
    });
  };

  const hasTag = (tagId) => {
    // console.log('user.tags', user.tags, tagId);
    let hasTag = false;
    user.tags.forEach((tag) => {
      if (tag === tagId) {
        hasTag = true;
      }
    });
    // console.log('hasTag', hasTag);
    return hasTag;
  };
  const changeTag = (tagId, active) => {
    const currentTags = user.tags.slice(0);
    // console.log('change tag', currentTags, tagId, active);
    if (active) {
      if (!hasTag(tagId)) {
        currentTags.push(tagId);
        setUser({
          ...user,
          tags: currentTags,
        });
      }
    } else {
      const newTags = [];
      currentTags.forEach((t) => {
        if (t !== tagId) {
          newTags.push(t);
        }
      });
      setUser({
        ...user,
        tags: newTags,
      });
    }
  };

  const handleChangeStartDate = (newStartDate) => {
    setUser({
      ...user,
      start_date: newStartDate,
    });
  };

  const handleChangeTerminationDate = (newTerminationDate) => {
    setUser({
      ...user,
      termination_date: newTerminationDate,
    });
  };

  const handleChangeDOB = (newDOB) => {
    setUser({
      ...user,
      dob: newDOB,
    });
  };

  const handleSponsor = async ({ id }) => {
    const res = await findUser(id) || null;
    setParent(res);
    setUser({
      ...user,
      parent_id: res.id,
    });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Add/Edit User">
      <div className={classes.container}>
        {
          loc.pathname === '/users/new'
          || (user && membership && !user.parent_id)
          || (user && membership && user.parent_id && parent) ? (
            < UserForm
              user={user}
              parent={parent}
              membership={membership}
              handleChange={handleChange}
              handleSponsor={handleSponsor}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeTerminationDate={handleChangeTerminationDate}
              handleChangeDOB={handleChangeDOB}
              hasTag={hasTag}
              changeTag={changeTag}
              isNew={isNew}
              userEvents={userEvents}
            />
            ) : (
              <div className={classes.loadingCircle}>
                <CircularProgress size={100} />
              </div>
            )
        }
      </div>
    </Layout>
  );
};

export default User;
