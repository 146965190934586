import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import useSWR from 'swr';
import { colors } from '../lib/styles';

const nameMap = {
  ep: 'Enrollment Paid',
  pc: 'Subscription Created',
  w9_signed: 'W9 Signed',
  ca_signed: 'CA Signed',
};

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const AgentEvents = () => {
  const classes = useStyles();
  const { data } = useSWR('/agent_events?by_agent=true&orderBy=created_at&sortOrder=desc&limit=500');

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'agent_num',
      headerName: 'Agent',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return (
          <Link to={`/users/${params.row.agent_id}/overview`} style={{ color: colors.darkAccent }}>
            {params.row.agent_num}
          </Link>
        );
      },
    },
    {
      field: 'agent_firstname',
      headerName: 'First',
      align: 'left',
      width: 150,
    },
    {
      field: 'agent_lastname',
      headerName: 'Last',
      align: 'left',
      width: 150,
    },
    {
      field: 'event_type',
      headerName: 'Event',
      align: 'left',
      width: 250,
      renderCell: (params) => {
        const { event_type } = params.row;
        return nameMap[event_type] || event_type;
      },
    },
    {
      field: 'created_at',
      headerName: 'Date',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        const { created_at } = params.row;
        const dateStr = moment(created_at).format('MM/DD/YYYY hh:mm A');
        return dateStr;
      },
    },
  ];

  return (
    <div style={classes.mainBody}>
      <Paper className={classes.tintedBackground}>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={data || []} />
        </div>
      </Paper>
    </div>
  );
};

export default AgentEvents;
