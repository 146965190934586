/* eslint-disable max-len */
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { forEach } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Joi from '@hapi/joi';
// import * as csv from 'csvtojson';
import { csv2json } from 'json-2-csv';
import moment from 'moment';
import { withStyles } from '@mui/styles';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';
import { Select } from '../lib/common';

const carrierMappings = {
  ALLZ: {
    name: 'ALLZ',
    policy_number: 'policy number',
    commission: 'commission amount',
    agent_full_name: 'agent name',
    policy_type: 'product type',
    statement_date: 'transaction date',
    premium_amount: 'comm base',
  },
  'F&G': {
    name: 'F&G',
    policy_number: 'policy number',
    commission: 'commission amt',
    agent_full_name: 'writing agent name',
    policy_type: 'coverage plan code',
    statement_date: 'transaction process date',
    premium_amount: 'commission basis',
  },
  NLG: {
    name: 'NLG',
    policy_number: 'policy #',
    commission: 'gross comm earned',
    agent_full_name: 'writing agt',
    policy_type: 'product',
    statement_date: 'payment date',
    premium_amount: 'premium amt',
  },
  EQUITR: {
    name: 'EQUITR',
    policy_number: 'policy number/billing mode',
    commission: 'comm earnings',
    agent_full_name: 'agent name',
    policy_type: 'type',
    premium_amount: 'comm premium',
  },
};

const styles = {
  btnStyle: {
    backgroundColor: 'rgba(32, 123, 204)',
    border: '1px solid blue',
    color: 'white',
    width: '175px',
  },
  errorMsg: {
    color: 'red',
  },
  inviteText: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  populatedECAList: {
    minHeight: '100px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    overflowY: 'scroll',
    paddingInlineStart: '25px',
    marginBottom: '20px',
  },
  unpopulatedECAlist: {
    height: '300px',
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  subHeader: {
    textAlign: 'center',
    color: colors.black,
  },
  forms: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const HiddenContent = (props) => {
  if (props.hidden) {
    return null;
  }
  return props.children;
};

// const defaultError = 'An error occurred submitting this list, please review your import file carefully to ensure and try again. If this problem persists contact support.';

const PreImportRow = (props) => {
  const { error, id } = props;
  return (
    <li style={props.isValid ? {} : { color: 'red' }}>
      <Typography variant="subtitle1">
        <span style={{ fontWeight: 'bold' }}>{props.carrier}</span>, {props.agent_full_name}, <span style={{ fontWeight: 'bold' }}>{props.policy_number}</span>, {props.product_name || ''}, {props.policy_type || ''}, {props.statement_date}, {props.premium_amount}, <span style={{ fontWeight: 'bold' }}>{props.commission}</span>
      </Typography>
      <span>
        {error && error.code === 422 ? (
          <span style={{ color: 'red' }}>
            {error.message}
            {error.bsf_id ? (
              <Link to={`/bsfs/${error.bsf_id}`} style={{ color: 'blue', marginLeft: '10px' }} target="_blank">
                BSF id {error.bsf_id}
              </Link>
            ) : ''}
          </span>
        ) : ''}
        {error && error.code !== 422 ? (
          <span style={{ color: 'red' }}>
            {error.message}
          </span>
        ) : ''}
        {id ? (
          <span style={{ color: 'green' }}>
            <CheckIcon />
          </span>
        ) : ''}
      </span>
    </li>
  );
};

const validationSchema = Joi.object({
  // affiliate: Joi.string().required(),
  // carrier: Joi.string().required(),
  agent_full_name: Joi.string().allow('', null),
  // client_name: Joi.string().required(),
  // client_dob: Joi.date(),
  // client_ssn: Joi.number().integer(),
  policy_number: Joi.string().required(),
  // product_name: Joi.string().required(),
  policy_type: Joi.string().allow('', null),
  statement_date: Joi.date().allow('', null),
  premium_amount: Joi.number().precision(2).allow(null, ''),
  commission: Joi.number().precision(2).required(),
  result: Joi.object(),
});

const ImportECA = () => {
  const [allValid, setAllValid] = useState(false);
  const [carrier, setCarrier] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [imports, setImports] = useState([]);
  const [submissionError, setSubmissionError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [forceUpload, setForceUpload] = useState(false);
  const [file, setFile] = useState(null);
  const history = useHistory();

  const handleAddFileContentsToState = async (fileContents) => {
    try {
      let data = await csv2json(fileContents, { delimiter: { eol: '\r\n' } });
      if (!data.length) {
        data = await csv2json(fileContents, { delimiter: { eol: '\n' } });
      }
      // console.log('data', data);
      const cleanedHeaders = data.map((rec) => {
        const newRec = {};
        const keys = Object.keys(rec);
        keys.forEach((k) => {
          if (k && k.trim) {
            let newKey = k.trim().toLocaleLowerCase();
            // console.log('newKey', newKey);
            newKey = newKey.replace(/[\r\n]+/g, '');
            // console.log('newKey2', newKey, rec[k]);
            if (newKey && (rec[k] !== undefined)) {
              newRec[newKey] = String(rec[k]).trim();
            }
          }
        });
        return newRec;
      });

      // console.log('cleanedHeaders', cleanedHeaders);

      const mapping = carrierMappings[carrier];
      const mappedRecords = cleanedHeaders.map((ch) => {
        const newRec = {
          carrier,
          statement_date: ch[mapping.statement_date],
          policy_number: ch[mapping.policy_number],
          commission: ch[mapping.commission],
          agent_full_name: ch[mapping.agent_full_name],
          policy_type: ch[mapping.policy_type],
          premium_amount: ch[mapping.premium_amount],
        };
        return newRec;
      });

      const filledRecords = [];
      // console.log('mappedRecords', mappedRecords);
      mappedRecords.forEach((mr) => {
        if (mr.policy_number) {
          filledRecords.push(mr);
          if (!mr.statement_date) {
            mr.statement_date = moment().toISOString();
          }
        }
      });
      // console.log('filledRecords', filledRecords);

      const cleanedRecords = filledRecords.map(({
        affiliate, carrier, agent_full_name, client_dob, client_ssn, policy_number, product_name, policy_type, statement_date, premium_amount, commission,
      }) => {
        let com = String(commission).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (com.startsWith('(')) {
          com = com.replaceAll('(', '');
          com = com.replaceAll(')', '');
          if (!com.startsWith('-')) {
            com = '-' + com;
          }
        }

        let prem = String(premium_amount).trim().replaceAll(',', '').replaceAll('$', '').replaceAll(' ', '');
        if (prem.startsWith('(')) {
          prem = prem.replaceAll('(', '');
          prem = prem.replaceAll(')', '');
          if (!prem.startsWith('-')) {
            prem = '-' + prem;
          }
        }

        const cr = {
          carrier: String(carrier).trim().toUpperCase(),
          policy_number: String(policy_number).trim(),
          policy_type: String(policy_type).trim(),
          statement_date: moment(String(statement_date).trim()).toISOString(),
          premium_amount: Number(prem),
          commission: Number(com),
          isValid: true,
        };
        if (affiliate) {
          cr.affiliate = String(affiliate).trim().toUpperCase();
        }
        if (agent_full_name) {
          cr.agent_full_name = String(agent_full_name).trim();
        }
        // if (client_name) {
        //   cr.client_name = String(client_name).trim();
        // }
        if (client_dob) {
          cr.client_dob = moment(String(client_dob).trim()).toISOString();
        }
        if (client_ssn) {
          cr.client_ssn = String(client_ssn).trim();
        }
        if (product_name) {
          cr.product_name = String(product_name).trim();
        }
        return cr;
      });

      cleanedRecords.forEach((cr) => {
        const validationResult = validationSchema.validate(cr, { allowUnknown: true });
        if (validationResult.error) {
          setValidationError(validationResult.error);
          cr.isValid = false;
          setAllValid(false);
          /* eslint-disable-next-line no-console */
          console.error(validationResult.error, cr);
        }
      });
      setImports(cleanedRecords);
    } catch (err) {
      setValidationError(err);
    }
  };
  const onChangeFile = (e) => {
    e.persist();
    const { files } = e.target;
    // Forces onChange to get called again if same file is resubmitted
    if (files && files.length) {
      setAllValid(true);
      forEach(files, (f) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        setFile(f);
        reader.onload = (readerEvent) => handleAddFileContentsToState(readerEvent.target.result);
        reader.readAsText(f);
      });
    }
  };

  const handleSubmitECAs = async () => {
    setSubmissionError('');
    setSubmitting(true);
    for (const imp of imports) {
      delete imp.result;
      delete imp.error;
    }
    const options = {
      method: 'POST',
      body: {
        force: forceUpload,
        ecas: imports,
      },
    };
    try {
      const batchResult = await apiFetch('/eca/batch', options);
      if (batchResult.errors) {
        batchResult.ecas.forEach((eca) => {
          if (eca.error) {
            setSubmissionError(eca.error.message);
            setForceUpload(true);
            eca.isValid = false;
          } else {
            eca.isValid = true;
          }
        });
        setImports(batchResult.ecas);
        setSubmissionError(false);
        return;
      }
      const formData = new FormData();
      formData.append('content_type', file.type);
      formData.append('file', file);
      formData.append('name', file.name);
      apiFetch.post('/comp_upload_files/files', formData)
        .then(() => {
          history.go('/eca');
        })
        .catch((err) => {
          /* eslint-disable-next-line no-console */
          console.error('err', err);
          history.go('/eca');
        });
    } catch (e) {
      setSubmissionError(e.message);
    }
    setSubmitting(false);
    // history.go(0);
  };
  const handleClearPreviousFile = (e) => {
    e.target.value = null;
  };
  const ecaRows = imports.map((i, idx) => {
    return (
      <PreImportRow
        key={i.policy_number + Math.random()}
        {...i}
        number={idx + 1}
      />
    );
  });

  return (
    <>
      <ol>
        <li>
          <Select
            label="Carrier"
            value={carrier}
            name="carrier"
            onChange={(e) => {
              setCarrier(e.target.value);
            }}
          >{Object.keys(carrierMappings).map((k) => (
            <MenuItem value={k} key={k}>{k}</MenuItem>
          ))}
          </Select>
        </li>
        {carrier && (
          <li>
            <Typography variant="subtitle1">
              {Object.keys(carrierMappings[carrier]).map((k) => {
                return (
                  <div key={k}>
                    <span style={{ fontWeight: 'bold' }}>{k}</span><span>{` : "${carrierMappings[carrier][k]}"`}</span>
                  </div>
                );
              })}
            </Typography>
          </li>
        )}
        <li><Typography variant="subtitle1">Upload a carrier specific file.</Typography></li>
        <li><Typography variant="subtitle1">Review the information is correct in the box below. Import can only proceed if all records are valid. If ECA is colored red it has information that is not formatted correctly or is invalid. Please verify the information and upload the file again.</Typography></li>
        <li><Typography variant="subtitle1">Once the ECAs have been reviewed click the &quot;Submit ECAs&quot; button below.</Typography></li>
      </ol>
      <Button
        color="primary"
        component="label"
        disabled={!carrier}
      >
        Upload File
        <input
          onClick={handleClearPreviousFile}
          onChange={onChangeFile}
          type="file"
          disabled={!carrier}
          style={{ display: 'none' }}
        />
      </Button>
      <Typography variant="h6" style={styles.subHeader}>ECAs to Insert</Typography>
      <HiddenContent hidden={!validationError}>
        <span className={styles.errorMsg}>{validationError.message}</span>
      </HiddenContent>
      <ol className={ecaRows.length ? styles.populatedECAList : styles.unpopulatedECAlist}>
        {ecaRows}
      </ol>
      <div>
        <Button
          gold
          disabled={!allValid && !forceUpload}
          onClick={handleSubmitECAs}
          style={{ margin: '20px' }}
        >
          {forceUpload ? 'Force ECAs to upload' : 'Submit ECAs'}
        </Button>
      </div>
      <HiddenContent hidden={!submissionError || submitting}>
        <span className={styles.errorMsg}>{submissionError}</span>
      </HiddenContent>
      <div style={{ marginBottom: '40px' }}/>
    </>
  );
};

export default withStyles(styles)(ImportECA);
