import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import moment from 'moment';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import DownloadButton from '../components/download-csv';
import { colors } from '../lib/styles';
import config from '../config';
import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';
import { Select, Button } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 80,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'qual_tier',
    headerName: 'Qual. Pillar',
    align: 'right',
    width: 110,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 200,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'statement_date',
    headerName: 'Month',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.statement_date ? params.row.statement_date.substring(0, 7) : ''}</span>;
    },
  },
  {
    field: 'account_num',
    headerName: 'Acc. Num',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.account_num) {
        return 'Y';
      }
      return <span style={{ color: 'red' }}>N</span>;
    },
  },
  {
    field: 'routing_num',
    headerName: 'Routing',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.routing_num) {
        return 'Y';
      }
      return <span style={{ color: 'red' }}>N</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 110,
  },
  {
    field: 'id',
    headerName: 'Details',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/override_details/${params.row.id}`} style={{ color: colors.darkAccent }}>Details</Link>;
    },
  },
  {
    field: 'edit',
    headerName: 'Edit',
    align: 'center',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/override-payments/${params.row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>;
    },
  },
];

const WealthSharePayments = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const [status, setStatus] = useState('all');
  const [lastMonth, setLastMonth] = useState(null);
  const [wsRunning, setWsRunning] = useState(false);
  const params = useParams();
  const { userId } = params;
  const { data: user } = useSWR(`/users/${userId}`);
  let url = '/override_payments?orderBy=created_at&limit=5000';
  if (userId) {
    url = `${url}&agent=${userId}`;
  }
  if (status !== 'all') {
    url = `${url}&status=${status}`;
  }

  const { data: payments, loading: paymentsLoading } = useSWR(url);
  // const dlName = user ? `${user.firstname}_${user.lastname}_${user.num}_override-payments` : 'override-payments';
  let cleanPayments = [];
  if (payments && payments.length) {
    cleanPayments = payments.map((p) => {
      return { ...p, amount: Number(p.amount) };
    });
  }

  useEffect(async () => {
    const lastStatus = await apiFetch('/override_payments/status');
    setLastMonth(lastStatus);
  }, []);

  const now = moment();
  const morning = moment().hour(8).minute(0).second(0);
  const passed8 = now.isAfter(morning);

  const handleRunWealthShare = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to run wealth share?')) {
      setWsRunning(true);
      const results = await apiFetch('/override_payments/run', { method: 'PUT', body: {} });
      setLastMonth(results);
      setWsRunning(false);
    }
  };

  return (
    <Layout headerTitle={`Wealth Share Payments ${user ? `for ${user.firstname} ${user.lastname} #${user.num}` : ''}`} >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          <Box gridColumn="span 3">
            <Select
              label="Status"
              value={status}
              name="state"
              onChange={(evt) => {
                setStatus(evt.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="OPEN">Open</MenuItem>
              <MenuItem value="PAID">Paid</MenuItem>
            </Select>
          </Box>
          {cleanPayments && cleanPayments.length ? (
            <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
              <DownloadButton rows={cleanPayments} name="override-payments" style={{ color: colors.darkAccent }}/>
            </Box>
          ) : ''}
        </Box>
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={cleanPayments} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
        <Button sx={{ marginTop: '10px' }} href={`${config.API_URL}/overrides/nacha`}>
          Download ACH
        </Button>
        <Button sx={{ marginTop: '10px', marginLeft: '10px' }} href="/upload/receipt">
          XML Records
        </Button>
      </div>
      {lastMonth ? (
        <div style={{ color: colors.darkAccent, margin: '10px' }}>
          <div style={{ color: colors.darkAccent }}>
            {lastMonth.month.substring(0, 7)} Total: ${Number(lastMonth.total).toFixed(2)}
            , {lastMonth.overrides.length} payments
          </div>
          {wsRunning ? (
            <CircularProgress/>
          ) : ''}
          <Button
            gold
            sx={{ margin: '15px' }}
            onClick={handleRunWealthShare}
            disabled={lastMonth.overrides.length || wsRunning || !passed8}
          >
            Run Wealth Share
          </Button>
        </div>
      ) : ''}
    </Layout>
  );
};

export default WealthSharePayments;
