import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';
import CarrierSearch from '../components/carrier-search';

const Carriers = () => {
  const history = useHistory();
  const columns = [
    {
      field: 'name',
      headerName: 'Carrier',
      align: 'left',
    },
    {
      field: 'code',
      headerName: 'Carrier Code',
      align: 'left',
    },
    {
      field: 'url',
      headerName: 'Link',
      align: 'right',
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
    },
  ];

  const { data: carriers, isValidating } = useSWR('/carriers?orderBy=order_val');

  let rows = [];
  if (carriers) {
    rows = carriers.map((row) => {
      const linkStyle = { color: colors.darkAccent };
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        name: row.name,
        code: row.code,
        order_val: row.order_val,
        url: row.url ? <a href={row.url} style={linkStyle} target="_blank" rel="noreferrer">Link</a> : '',
        edit: <Link to={`/carriers/${row.id}/edit`} style={linkStyle}>Edit</Link>,
      };
    });
  }

  return (
    <div>
      <CarrierSearch
        onChange={(carrier) => {
          if (carrier) {
            history.push(`/carriers/${carrier.id}/edit`);
          }
        }}
      />
      <ConfigTypesLayout
        rows={rows}
        isValidating={isValidating}
        columns={columns}
        title="Carrier"
        path="carriers"
        order="name"
      />
    </div>
  );
};

export default Carriers;
