import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid } from '@mui/x-data-grid';
import useSWR from 'swr';
import { colors } from '../lib/styles';
import { formatCurrency } from '../lib/util';
import DownloadButton from './download-csv';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'carrier_name',
    headerName: 'Carrier',
    align: 'right',
    width: 140,
  },
  {
    field: 'product_type_name',
    headerName: 'Product',
    align: 'right',
    width: 140,
  },
  {
    field: 'first_distribution',
    headerName: 'First Distribution',
    align: 'right',
    width: 170,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.first_distribution || '0.00')}</span>;
    },
  },
  {
    field: 'life_target',
    headerName: 'Life Target',
    align: 'right',
    width: 170,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.life_target || '0.00')}</span>;
    },
  },
  {
    field: 'death_benefit',
    headerName: 'Death Benefit',
    align: 'right',
    width: 170,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.death_benefit || '0.00')}</span>;
    },
  },
  {
    field: 'initial_funding_amount',
    headerName: 'Initial Funding',
    align: 'right',
    width: 170,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.initial_funding_amount || '0.00')}</span>;
    },
  },
  {
    field: 'contract_basis',
    headerName: 'Contract Basis',
    align: 'right',
    width: 170,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.contract_basis || '0.00')}</span>;
    },
  },
  {
    field: 'created',
    headerName: 'Date',
    align: 'right',
    width: 160,
    renderCell: (params) => {
      return <span>{params.row.created.substring(0, 10)}</span>;
    },
  },
];

function PaymentsTable({ data, display, timeType }) {
  const classes = useStyles();
  return (
    <div className={classes.mainBody} style={{ display }}>
      <DownloadButton rows={data} name={`carrier_stats_${timeType}`}/>
      <Paper className={classes.tintedBackground}>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={data || []} />
        </div>
      </Paper>
    </div>
  );
}

const CarrierStats = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { data: stats } = useSWR('/admin/carrier_stats');
  let months = [];
  let quarters = [];
  let years = [];
  if (stats) {
    months = stats.bsf_month.map((s, idx) => {
      s.id = idx + 1;
      return s;
    });

    quarters = stats.bsf_quarter.map((s, idx) => {
      s.id = idx + 1;
      return s;
    });

    years = stats.bsf_year.map((s, idx) => {
      s.id = idx + 1;
      return s;
    });
  }

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={(e, v) => {
          setTabIndex(v);
        }}
        centered
      >
        <Tab label="Year"/>
        <Tab label="Quarter"/>
        <Tab label="Month"/>
      </Tabs>
      <PaymentsTable
        data={years}
        display={tabIndex === 0 ? '' : 'none'}
        timeType="year"
      />
      <PaymentsTable
        data={quarters}
        display={tabIndex === 1 ? '' : 'none'}
        timeType="quarter"
      />
      <PaymentsTable
        data={months}
        display={tabIndex === 2 ? '' : 'none'}
        timeType="month"
      />
    </div>
  );
};

export default CarrierStats;
